<template>
    <div class="info-modal">
        <img class="info m-3" v-b-modal.modal-1 src="@/assets/info.svg" title="Uitleg meetpunten">

        <b-modal id="modal-1" size="lg" title="Uitleg meetpunten" ok-only>
            <p class="my-4">Hoe om te gaan met meetpunten? <br>
                Standaard is het eerste meetpunt altijd 100% van het wettelijk minimum (de bijstandsnorm). <br>
                Een volgend meetpunt is een percentage van de geldende bijstandsnorm, hoger dan 100%. <br>
                Via de <img class="plus mx-2 mb-1" src="@/assets/plus.svg" alt=""> kan een volgend meetpunt worden toegevoegd. <br>
                Herhaal deze handeling totdat alle gewenste meetpunten zijn ingegeven, maximaal 9. <br>
                BeleidloonT zal dan voor ieder meetpunt, voor de ingevoerde gezinssituatie, de toeslagen berekenen.
            </p>
        </b-modal>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        open: false,
      }
    },
    methods: {},
  }
</script>
<style lang="scss">
    .modal-backdrop {
      opacity: 0.8;
      background: silver;
    }
    .info-modal {
        img.info {
          width: 20px;
          border-radius: 10px;
          position: absolute;
          left: 130px;
          top: 6px;
          border: 1px solid;
          padding: 3px;
        }
        &:hover {
            cursor: pointer;
        }
    }

    #modal-1 {
        color: #313131;
        font-family: 'Open Sans', sans-serif;
        font-weight: 300;
        line-height: 1.5rem;
        p {
            line-height: 1.8rem;
        }
        .btn {
            &.btn-primary {
                background-color: #af197d;
                border-color: #af197d;
                min-width: 150px;
            }

            &:hover {
                opacity: .4;
            }
        }
      .modal-content {
        border-radius: 0;
      }
    }
</style>
