export default {

  huurtoeslagFactoren: {
    "aftoppingsgrenshoog": 697.07,
    "aftoppingsgrenslaag": 650.43,
    "aftoppingsgrenspercentage": 40,
    "basishuur": 192,
    "factor_a_een": 0.00000041337,
    "factor_a_meer": 0.00000024449,
    "factor_b_een": 0.00239349,
    "factor_b_meer": 0.00180784,
    "inkomensijkpunthoog": 26975,
    "inkomensijkpuntlaag": 20700,
    "kwaliteitskortingsgrens": 454.47,
    "kwaliteitskortingsgrenspercentage": 65,
    "maxhuur": 879.66,
    "maxhuurjongeren": 454.47,
    "normhuurverhoging": -34.67
  },

  kindgebondenbudgetFactoren: {
    'drempelinkomen': 26819,
    'drempelinkomenalleenstaande': 26819,
    'drempelinkomenechtpaar': 35849,
    'kind1': 2436,
    'kind2': 4872,
    'kind3': 7308,
    'ophoging12jaar': 694,
    'ophoging16jaar': 924,
    'ophogingalleenstaande': 3480,
    'ophogingmeerkind': 2436,
    'verlagingmeerinkomenpercentage': 6.75
  },

  kinderopvangFactoren: {
    "maxurenpermaand": 230,
    "maxuurbuitenschoolseopvang": 9.12,
    "maxuurdagopvang": 10.25,
    "maxuurgastouderopvang": 7.53,
    "uurpercentagehoog": 140,
    "uurpercentagelaag": 140
  },

  toetsingsinkomenFactoren: {
    'AHKbedrag': 3362,
    'AHKmax': 75518,
    'AHKmin': 24813,
    'AHKpercentage': 6.63,
    'percentagehoog': 49.5,
    'percentagelaag': 36.97,
    'zvwPercentage': 6.57
  },

  arbeidskortingWaarden: {
    0: [0, 8.425],
    11491: [968, 31.433],
    24821: [5158, 2.471],
    39958: [5532, 6.51],
    124935: [0, 0]
  },
  kinderopvangWaarden: {
    0: [96.00,	96.00],
    22347: [96.00,	96.00],
    23835: [96.00,	96.00],
    25321: [96.00,	96.00],
    26811: [96.00,	96.00],
    28298: [95.50,	95.60],
    29787: [94.40,	95.40],
    31274: [93.40,	95.20],
    32758: [92.50,	95.00],
    34358: [91.90,	94.90],
    35956: [90.90,	94.70],
    37558: [90.40,	94.50],
    39156: [89.50,	94.50],
    40760: [88.70,	94.50],
    42360: [88.10,	94.50],
    43998: [87.30,	94.50],
    45638: [86.60,	94.50],
    47279: [85.90,	94.50],
    48919: [85.00,	94.50],
    50563: [84.50,	94.50],
    52202: [83.70,	94.50],
    53842: [83.00,	94.50],
    55483: [82.10,	94.50],
    57276: [80.60,	94.50],
    60792: [79.80,	94.10],
    64306: [78.70,	93.50],
    67822: [76.40,	93.10],
    71340: [74.10,	92.80],
    74854: [71.90,	92.10],
    78372: [69.40,	91.60],
    81887: [67.10,	91.10],
    85403: [64.90,	90.40],
    88920: [62.50,	89.80],
    92434: [60.30,	89.40],
    95955: [57.80,	89.10],
    99470: [55.50,	88.40],
    102984: [53.30,	88.00],
    106500: [50.90,	87.50],
    110085: [48.80,	86.80],
    113687: [46.70,	86.30],
    117287: [44.60,	85.90],
    120888: [42.40,	85.60],
    124486: [40.50,	84.90],
    128089: [38.60,	84.30],
    131691: [36.70,	83.90],
    135293: [34.70,	83.30],
    138890: [33.30,	82.90],
    142490: [33.30,	82.20],
    146093: [33.30,	81.60],
    149692: [33.30,	80.60],
    153293: [33.30,	80.30],
    156892: [33.30,	79.50],
    160495: [33.30,	78.60],
    164099: [33.30,	78.00],
    167697: [33.30,	77.10],
    171298: [33.30,	76.60],
    174896: [33.30,	75.80],
    178499: [33.30,	75.10],
    182101: [33.30,	74.40],
    185702: [33.30,	73.40],
    189302: [33.30,	72.90],
    192899: [33.30,	72.20],
    196503: [33.30,	71.40],
    200102: [33.30,	70.70],
    203704: [33.30,	70.10],
    207305: [33.30,	69.30],
    210904: [33.30,	68.50],
    214506: [33.30,	68.00],
    218105: [33.30,	67.10]
  },

  wozWaarden: {
    0: [0, 0],
    12500: [0.10, 0],
    25000: [0.20, 0],
    50000: [0.25, 0],
    75000: [0.35, 0],
    1320000: [2.35, 4620]
  },

  zorgtoeslagWaarden: {
    0: [123, 236],
    26500: [123, 236],
    27000: [121, 233],
    27500: [115, 228],
    28000: [110, 222],
    28500: [104, 216],
    29000: [98,  211],
    29500: [93,  205],
    30000: [87,  199],
    30500: [81,  194],
    31000: [75,  188],
    31500: [70,  182],
    32000: [64,  177],
    32500: [58,  171],
    33000: [53,  165],
    33500: [47,  159],
    34000: [41,  154],
    34500: [36,  148],
    35000: [30,  142],
    35500: [24,  137],
    36000: [19,  131],
    36500: [13,  125],
    37000: [7,   120],
    37500: [0,  114],
    38000: [0,  108],
    38500: [0,  102],
    39000: [0,  97],
    39500: [0,  91],
    40000: [0,  85],
    40500: [0,  80],
    41000: [0,  74],
    41500: [0,  68],
    42000: [0,  63],
    42500: [0,  57],
    43000: [0,  51],
    43500: [0,  46],
    44000: [0,  40],
    44500: [0,  34],
    45000: [0,  28],
    45500: [0,  23],
    46000: [0,  17],
    46500: [0,  11],
    47000: [0,  6],
    47368: [0,  0]
  },
}