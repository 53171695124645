import Vue from 'vue';
import store from "./store";
import App from './App.vue'
import axios from "axios";
import VTooltip from "v-tooltip"
import VueVega from 'vue-vega'
import BootstrapVue from 'bootstrap-vue'
import Login from './components/Login';
import Meetpunten from './components/Meetpunten';
import Barchart from './components/Barchart';
import Modal from './components/Modal';
import constanten from '@/components/rekenwaarden/2024.js';
import {InlineSvgPlugin} from 'vue-inline-svg';

/* eslint-disable */
const AxiosConfig = require('./configs/config.axios');

Vue.use(VTooltip);
Vue.prototype.$axios = axios;
Vue.use(VueVega);
Vue.use(BootstrapVue);
Vue.use(InlineSvgPlugin);

axios.defaults.baseURL = process.env.VUE_APP_URL;

if(location.host.substr(0,9) === 'localhost' || location.host.substr(0,3) === 'bur' || location.host.substr(0,7) === '192.168'){
  axios.defaults.baseURL = location.protocol+'//bur.test';
  console.log('Connected to: LOCAL API');
}
if(location.host.substr(0,25) === 'dev.berekenuwrechtplus.nl'){
  axios.defaults.baseURL = location.protocol+'//dev.berekenuwrechtplus.nl';
  console.log('Connected to: DEV API');
}

Vue.component('login', Login);
Vue.component('barchart', Barchart);
Vue.component('meetpunten', Meetpunten);
Vue.component('modal', Modal);

Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})

Vue.mixin({
  data: function () {
    return {
      c: constanten
    }
  },
  methods:
    {
      afgerond(decimal) {
        return Math.round(decimal * 100) / 100;
      },

      geefNettoLoonUitBruto(brutoloonpermaand) {
        const percentagelaag = this.c.toetsingsinkomenFactoren.percentagelaag;
        const percentagehoog = this.c.toetsingsinkomenFactoren.percentagehoog;
        const AHKmin = this.c.toetsingsinkomenFactoren.AHKmin;
        const AHKmax = this.c.toetsingsinkomenFactoren.AHKmax;
        const AHKpercentage = this.c.toetsingsinkomenFactoren.AHKpercentage;
        const AHKbedrag = this.c.toetsingsinkomenFactoren.AHKbedrag;

        // ondergrens plus percentage
        const arbeidskortingWaarden = this.c.arbeidskortingWaarden

        let brutoinkomenperjaar = brutoloonpermaand * 12;

        let inkomen2 = brutoinkomenperjaar - AHKmax;
        if (inkomen2 < 0) {
          inkomen2 = 0;
        }
        let belasting2 = 0;
        if (inkomen2 > 0) {
          belasting2 = inkomen2 * percentagehoog / 100;
        }
        belasting2 = Math.round(belasting2, 0);

        let inkomen1 = 0;
        let belasting1 = 0;
        if (brutoinkomenperjaar > AHKmax) {
          inkomen1 = AHKmax;
        }
        if (brutoinkomenperjaar <= AHKmax) {
          inkomen1 = brutoinkomenperjaar;
        }
        belasting1 = inkomen1 * percentagelaag / 100;
        belasting1 = Math.round(belasting1, 0);

        let belasting = belasting1 + belasting2;
        let belastingpermaand = belasting / 12;


        let arbeidskorting = 0;
        let min = this.ondergrens(brutoinkomenperjaar, arbeidskortingWaarden);
        let plus = arbeidskortingWaarden[min][0];
        let percentage = arbeidskortingWaarden[min][1];
        let arbeidskorting_ID = Object.keys(arbeidskortingWaarden).indexOf(min) + 1

        switch (arbeidskorting_ID) {
          case 1:
            arbeidskorting = percentage / 100 * brutoinkomenperjaar;
            break;
          case 2:
          case 3:
            arbeidskorting = plus + (percentage / 100 * (brutoinkomenperjaar - min));
            break;
          case 4:
            arbeidskorting = plus - (percentage / 100 * (brutoinkomenperjaar - min));
            break;
          case 5:
            arbeidskorting = 0;
            break;
        }


        arbeidskorting = Math.round(arbeidskorting, 0);
        let arbeidskortingpermaand = arbeidskorting / 12;

        let AHK = null;

        if (brutoinkomenperjaar <= AHKmin) {
          AHK = AHKbedrag;
        } else if (brutoinkomenperjaar > AHKmin
          &&
          brutoinkomenperjaar <= AHKmax
        ) {
          AHK = AHKbedrag - ((brutoinkomenperjaar - AHKmin) * AHKpercentage / 100);
          AHK = Math.round(AHK, 0);

        } else {
          AHK = 0;
        }

        let AHKpermaand = AHK / 12;
        let loonheffing = belastingpermaand - arbeidskortingpermaand - AHKpermaand;
        if (loonheffing < 0) {
          loonheffing = 0;
        }
        let nettoloon = brutoloonpermaand - loonheffing;
        return Math.round(nettoloon);
      },

      ondergrens(needle, haystack) {
        let keys = Object.keys(haystack);
        let ondergrens;
        keys.forEach((key) => {
          if (parseInt(needle) >= parseInt(key)) {
            ondergrens = key;
          }
        });
        return ondergrens;
      },

      vindBrutoUitNetto(min, max, i=0, halveBandbreedte = 3) {
        min = parseInt(min);
        i++
        if(i % 25 === 0) halveBandbreedte++
        let poging = this.geefNettoLoonUitBruto(max);
        let ondergrens = parseInt(min - halveBandbreedte);
        let bovengrens = parseInt(min + halveBandbreedte);
        if (poging < ondergrens || poging > bovengrens) {
          let nieuwe_max;
          if (poging > bovengrens) {
            nieuwe_max = Math.floor((max - min) / 2) + min;
          } else {
            nieuwe_max = Math.floor((max - min) / 2) + max;
          }
          return this.vindBrutoUitNetto(min, nieuwe_max, i,halveBandbreedte);
        } else {
          return max;
        }
      }
    }
});

//dit MOET onderaan staan: het renderen van de Vue instantie met alle componenten
new Vue({
  store: store,
  render: h => h(App),
}).$mount('#app');


