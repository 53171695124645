<template>
  <div class="logo-container" @click="loguit()">
    <img v-if="logo" class="logo" :src="logo" alt="logo">
  </div>
</template>

<script>
export default {
  computed: {
    gemeente(){
      return this.$store.state.default.gemeente;
    },
    logo() {
      return 'https://www.berekenuwrechtplus.nl/logo/logo' + this.gemeente.gemcode + '.' + this.gemeente.extensie;
    },
  }
}
</script>

<style scoped>
  .logo {
    max-height: 60px;
    max-width: 300px;
  }
</style>