<template>
  <div id="app">
    <BeleidloonT/>
  </div>
</template>

<script>
import BeleidloonT from './components/Beleidloont.vue'

export default {
  name: 'app',
  components: {
    BeleidloonT
  }
}
</script>

<style>

</style>
