<template>
    <div>
        <div id="viz"></div>
    </div>
</template>>
<!--https://fabiofranchino.com/blog/render-vega-and-vega-lite-source-in-vue-js/-->
<script>
  // todo bereken ververst niet kinderdata
  // todo y1 kan misschien weg
  // todo opnieuw berekenen ververst niet graph
  // todo graph ververst uberhaupt niet
  // todo het is mogelijk om te berekenen met geen meetpunten
  // todo hoe moeten de kosten van kinderopvang in de graph verwerkt worden
  // todo foutmelding overnemen van werkloont: lege velden en berekenknop
  // breedte van de grafiek afh van het aantal kolommen
  import embed from 'vega-embed';

  export default {
    props: ['data', 'aantal'],
    data() {
      return {
        def: {
          "$schema": "https://vega.github.io/schema/vega/v5.json",
          "description": "A basic stacked bar chart example.",
          "width": this.aantal * 75,
          "height": 600,
          "padding": 5,
          "data": [
            {
              "name": "table",
              "values": [],
              "transform": [
                {
                  "type": "stack",
                  "groupby": [
                    "x"
                  ],
                  "sort": {
                    "field": "c"
                  },
                  "field": "y"
                }
              ]
            }
          ],
          "scales": [
            {
              "name": "x",
              "type": "band",
              "range": "width",
              "domain": {
                "data": "table",
                "field": "x"
              }
            },
            {
              "name": "y",
              "type": "linear",
              "range": "height",
              "nice": true,
              "zero": true,
              "domain": {
                "data": "table",
                "field": "y1"
              }
            },
            {
              "name": "color",
              "type": "ordinal",
              "range": "category",
              "domain": {
                "data": "table",
                "field": "c"
              }
            }
          ],
          "axes": [
            {
              "orient": "bottom",
              "scale": "x",
              "zindex": 1
            },
            {
              "orient": "left",
              "scale": "y",
              "zindex": 1
            }
          ],
          "marks": [
            {
              "type": "rect",
              "from": {
                "data": "table"
              },
              "encode": {
                "enter": {
                  "x": {
                    "scale": "x",
                    "field": "x"
                  },
                  "width": {
                    "scale": "x",
                    "band": 1,
                    "offset": -1
                  },
                  "y": {
                    "scale": "y",
                    "field": "y0"
                  },
                  "y2": {
                    "scale": "y",
                    "field": "y1"
                  },
                  "fill": {
                    "scale": "color",
                    "field": "c"
                  }
                },
                "update": {
                  "fillOpacity": {
                    "value": 1
                  }
                },
                "hover": {
                  "fillOpacity": {
                    "value": 0.5
                  }
                }
              }
            }
          ],
          "legends": [
            {
              "fill": "color",
              "orient": "top"
            }
          ]
        }
      }
    },
    watch: {
      // whenever data changes, this function will run
      data: function () {
        this.def.data[0].values = this.data;
        this.def.width = this.aantal * 75;
        embed('#viz', this.def, {
          actions: {
            export: true,
            source: false,
            compiled: false,
            editor: false
          },
        })
      }
    },
  }
</script>