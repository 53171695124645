<template>
  <div>
    <div id="meetpunten" class="card px-5 py-3 mt-5" :class="{'border-danger': warning && !voldoendeMeetpunten}">
      <h4 class="card-title">
        Meetpunten
        <modal></modal>
      </h4>

      <div class="card-body d-flex  mt-3">
        <div class="d-flex" :key="i" v-for="(m,i) in meetpunten">
          <input class="form-control" type="number" step="5" v-model="meetpunten[i]" @blur="opslaan" v-focus>
          <span class="mt-2 ml-1 mr-3">%</span>
        </div>
        <div class="position-relative">
          <img v-if="meetpunten.length <9" class="m-3" @click="add()" @keyup.enter="add()" src="@/assets/plus.svg"
               tabindex="0">
          <img class="m-3" @click="remove()" src="@/assets/min.svg">
        </div>
      </div>

      <p class="alert alert-danger m-3" v-if="warning && !voldoendeMeetpunten">Kies meer dan 1 meetpunt en alle boven
        100%</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['warning'],
  data() {
    return {
      meetpunten: ['100'],
    }
  },
  methods: {
    add() {
      let max = this.meetpunten.length - 1;
      if (this.meetpunten[max] !== null && max < 8) {
        this.meetpunten.push(null);
      }
    },
    opslaan() {
      this.meetpunten.sort((a, b) => a - b)
      this.$emit('meetpunten', this.meetpunten);
      const filteredMeetpunten = [];
      this.meetpunten.forEach((x) => {
        if (x !== null && x.trim() !== '' && !filteredMeetpunten.includes(x)) filteredMeetpunten.push(x)
      })
      this.meetpunten = filteredMeetpunten;
    },
    remove() {
      this.meetpunten.pop();
    },
  },
  computed: {
    voldoendeMeetpunten() {
      const kleinerDanHonderd = (i) => i < 100;
      return this.meetpunten.length > 1 && !this.meetpunten.some(kleinerDanHonderd)
    },
  }
}
</script>

<style lang="scss" scoped>
#meetpunten {
  position: relative;
  color: #313131;
  font-family: 'Open Sans', sans-serif;

  h4 {
    color: #af197d;
  }

  img:hover {
    cursor: pointer;
  }

  input[type='number'] {
    width: 80px;
  }

  input[type='number']:first-of-type {
    margin-left: 0 !important;
  }

  .info-modal {
    position: absolute;
    top: 0;
  }

  .position-relative {
    top: -8px;
  }
}
</style>