import Vue from 'vue';
import Vuex from "vuex";
import axios from 'axios';

Vue.use(Vuex);

const store = new Vuex.Store({
    namespaced: true,
    state: {
        loading: false, // om een laadscherm te tonen
        loggedIn: null, // gebruiker is ingelogd, maar heeft misschien geen toegang tot deze module
        authenticated: false, // gebruiker heeft toegang tot deze module
        authenticationMessage: '', // Error bij het inloggen
        user:{},
        default: {
            aDebug: [],
            aGraphData: [],
            admin: false,
            armoedeval: false,
            bedragenarmoedeval: [],
            betaalde_alimentatie: '',
            betaalde_alimentatie_partner: '',
            checkingevuld: false,
            extensie: '',
            rows: [],
            gemcode: 0,
            gemeente: {},
            kindgebondenbudgetdata: [],
            maanden: 12,
            maanden_partner: 12,
            eenmalige_uitkering: '',
            eenmalige_uitkering_partner: '',
            financieringskosten: 0,
            handleidingurl: '',
            huur: '',
            hypotheekrente: 0,
            inkomenuitbijstand: 0,
            kind0: 0,
            kind4: 0,
            kind12: 0,
            kind16: 0,
            leeftijd: '',
            leeftijd_partner: 0,
            loggedIn: false,
            loon: '',
            loon_partner: 0,
            loonsoort: null,
            loonsoort_partner: 'F',
            loonsoorten: ['N', 'B', 'F'],
            meetpunten: [],
            nettouitbrutooffiscaal: [0, 0],
            ontvangen_alimentatie: '',
            ontvangen_alimentatie_partner: '',
            partner: '',
            regelingen: [],
            showkinderen: '',
            som_werk: 0,
            som_bijstand: 0,
            submitted: false,
            recht: [],
            toeslag_: [],
            toeslagmin_: [],
            uurprijs: [],
            uurprijstoegestaan: [],
            uren: [],
            uren_werk_per_maand: null,
            uurpercentages: [],
            urentoegstaan: [],
            vega: [],
            verwervingskosten: '',
            woning: '',
            woningaanpassing: '',
            woz: 0,
            woz_ondergrens: null,
            woz_factoren: null,
            zt: false,
            zt_ondergrens: null,
            aInkomens: [],
        },
    },
    getters: {
        getState(state) {
            return state;
        },
        loading(state) {
            return state.loading;
        },
        authenticated(state) {
            return state.authenticated
        },
        authenticationMessage(state) {
            return state.authenticationMessage;
        },
        loggedIn(state) {
            return state.loggedIn;
        },
        user(state) {
            return state.user
        }
    },
    mutations: {
        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_ERROR (state, value) {
            state.authenticationMessage = value
        },
        SET_LOGGEDIN (state, value) {
            state.loggedIn = value
        },
        SET_GEBRUIKER (state, data) {
            state.user = data
        },
        SET_GEMEENTE (state, data) {
            state.default.gemeente = data
        },
    },
    actions: {
        signOut({commit}){
            return axios.get('/api/uitloggen').then(() => {
                commit('SET_GEBRUIKER', {})
                commit('SET_ERROR', '')
                commit('SET_GEMEENTE', {})
                commit('SET_AUTHENTICATED', false)
                commit('SET_LOGGEDIN', false)
            }).catch(() => {
                console.error('Uitloggen mislukt');
            });
        },

        async signIn ({ commit }) {
            commit('SET_LOADING', true);
            return axios.get('/api/gebruiker').then((response) => {
                if(Object.keys(response.data).length > 0){
                    commit('SET_AUTHENTICATED', true)
                    commit('SET_ERROR', '');
                    commit('SET_GEBRUIKER', response.data.user)
                    commit('SET_GEMEENTE', response.data.gemeente)
                    commit('SET_LOADING', false);
                } else {
                    commit('SET_AUTHENTICATED', false)
                    commit('SET_ERROR', 'Inloggen mislukt')
                    commit('SET_LOGGEDIN', false)
                    commit('SET_GEBRUIKER', {})
                    commit('SET_LOADING', false);
                }
            }).catch((error) => {
                if(error.response.status === 403){
                    commit('SET_ERROR', 'U heeft geen toegang tot deze module');
                }
                commit('SET_AUTHENTICATED', false)
                commit('SET_LOGGEDIN', false)
                commit('SET_GEBRUIKER', {})
                commit('SET_LOADING', false);
            });

        },
        setLoggedIn({commit}){
            commit('SET_LOGGEDIN', true)
        }

    }
});

export default store;